import React, { Component } from 'react'

export default class Error extends Component {
  componentDidMount() {
    location.href = "/";
  }
  render() {
    return (
      <div>
        <p>Sorry, page not found!</p>
      </div>
    )
  }
}

